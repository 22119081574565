import { faGift } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getIsUserProvider } from '../../../util/new/currentUser'
import { Button } from '../Button'
import { StellaFilloutPopupEmbed } from '../StellaFilloutPopupEmbed'
import { VoucherDialog } from './components'

const CreditCardContent = ({
  isLoggedIn,
  isSmall,
  onButtonClick,
  signUpLoading,
  location,
  displayName
}) => {
  const buttonProps = isLoggedIn
    ? {
        onClick: onButtonClick,
        children: 'View $100 Credit'
      }
    : {
        // href: signUpUrl(location.pathname + location.search),
        onClick: onButtonClick,
        isLoading: signUpLoading,
        children: isSmall ? 'Get $100 Credit' : 'Get $100 Credit'
      }

  return isSmall ? (
    <Button uiType={'primary'} {...buttonProps} />
  ) : (
    <Paper style={{ padding: 14, backgroundColor: '#EBF6F8' }} elevation={1}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box marginRight={2}>
          <FontAwesomeIcon icon={faGift} size="2x" style={{ color: '#006D77' }} />
        </Box>
        <Box flexGrow={1} marginRight={2}>
          <Typography variant={'body1'} style={{ color: '#006D77' }}>
            {isLoggedIn ? 'View Your $100 Credit Voucher' : 'Get $100 Credit Voucher'}
          </Typography>
          {displayName ? (
            <Typography variant="caption" style={{ color: '#006D77' }}>
              Redeem {isLoggedIn ? 'your' : 'the'} $100 credit when booking with {displayName}.
            </Typography>
          ) : (
            <Typography variant="caption" style={{ color: '#006D77' }}>
              Redeem {isLoggedIn ? 'your' : 'the'} $100 credit with participating practitioners.
              <br />
              Look for the <b style={{ color: 'var(--marketplaceColor)' }}>
                Accepts Stella Credit
              </b>{' '}
              badge on practitioners' profiles.
            </Typography>
          )}
        </Box>
        <Button uiType="primary" {...buttonProps} />
      </Box>
    </Paper>
  )
}

export const GetCreditCard = ({ currentUser, listing }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const location = useLocation()

  const [signUpLoading, setSignUpLoading] = useState(false)
  const [showVoucher, setShowVoucher] = useState(false)
  const [showFillout, setShowFillout] = useState(false)

  const isLoggedIn = useMemo(() => !!currentUser, [currentUser])
  const isProvider = useMemo(() => getIsUserProvider(currentUser), [currentUser])

  if (isProvider) return null

  const handleButtonClick = () => {
    if (!isLoggedIn) {
      // setSignUpLoading(true)
      setShowFillout(true)
    } else {
      setShowVoucher(true)
    }
  }

  return (
    <>
      <CreditCardContent
        isLoggedIn={isLoggedIn}
        isSmall={isSmall}
        onButtonClick={handleButtonClick}
        signUpLoading={signUpLoading}
        location={location}
        displayName={listing?.displayName}
      />
      <VoucherDialog
        showVoucher={showVoucher}
        setShowVoucher={setShowVoucher}
        currentUser={currentUser}
        displayName={listing?.displayName}
      />

      {showFillout && (
        <StellaFilloutPopupEmbed filloutId="427A6zHRf7us" onClose={() => setShowFillout(false)} />
      )}
    </>
  )
}
